@import './shared/styles/_vuetify-overrides.scss';
































































































.cassie-table.cassie-table {
  tr:hover:not(.v-data-table__expanded__content) {
    cursor: pointer !important;
    background-color: var(--global-main-background-color) !important;
  }
}

